import palette from '../../../themev5/palette';

const styles = {
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: '26px',
      backgroundColor: palette.white.main,
      width: '672px',
      height: '420px',
      maxWidth: '672px',
      maxHeight: '420px',
      padding: '4px',
      background: `linear-gradient(${palette.white.main}, ${palette.white.main}), linear-gradient(to right, ${palette.primary['550']} 4px, transparent), linear-gradient(to left, #37B45B 4px, transparent)`,
      backgroundClip: 'content-box, border-box, border-box, border-box',
      backgroundOrigin: 'content-box, border-box, border-box, border-box'
    },
    '& .MuiDialog-paperFullScreen': {
      backgroundColor: 'transparent'
    }
  },
  headerWrapper: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  headerTitle: {
    color: palette.primary.darkVariant1,
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontWeight: 600
  },
  closeButton: { '& svg': { height: '14px' } },
  questionWrapper: { textAlign: 'center', padding: '32px 0' },
  questionTitle: {
    color: palette.gray[550],
    fontFamily: 'Mulish',
    fontSize: '12px',
    fontWeight: 600
  },
  questionText: {
    color: palette.black.main,
    fontFamily: 'Mulish',
    fontSize: '20px',
    fontWeight: 700
  },
  dialogContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1
  },
  buttonTypeQuestion: {
    wrapper: { display: 'flex', gap: '16px' },
    button: {
      height: '84px',
      width: '108px',
      borderRadius: '10px',
      background: palette.white.smokeDark,
      color: palette.primary.darkVariant1,
      fontSize: '14px',
      fontFamily: 'Mulish',
      fontWeight: 700,
      boxShadow: 'none',
      textTransform: 'capitalize',
      '&:hover': {
        background: palette.primary.textVariant,
        color: palette.gray[50],
        boxShadow: 'none'
      }
    }
  },
  emojiButtonTypeQuestion: {
    wrapper: { display: 'flex', gap: '16px' },
    button: {
      height: '108px',
      width: '108px',
      borderRadius: '10px',
      background: palette.white.smokeDark,
      color: palette.primary.darkVariant1,
      fontSize: '14px',
      fontFamily: 'Mulish',
      fontWeight: 700,
      boxShadow: 'none',
      textTransform: 'capitalize',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      textAlign: 'center',
      padding: '14px',
      '&:hover': {
        background: palette.primary.textVariant,
        color: palette.gray[50],
        boxShadow: 'none',
        cursor: 'pointer'
      }
    },
    optionText: {
      fontSize: '12px',
      fontFamily: 'Mulish',
      fontWeight: 700,
      color: 'inherit',
      height: '36px'
    }
  },
  radioTypeQuestion: {
    radioWrapper: { display: 'flex', justifyContent: 'space-between', padding: '0 10px' },
    radio: {
      '& .MuiSvgIcon-root': {
        fontSize: '16px'
      }
    },
    divider: { m: '5px 0 14px 0', borderStyle: 'dotted' },
    optionsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      textAlign: 'center',
      padding: '0 10px'
    },
    optionText: {
      fontSize: '12px',
      fontFamily: 'Mulish',
      fontWeight: 700,
      color: palette.primary.darkVariant1,
      height: '30px',
      width: '38px'
    }
  },
  inputTypeQuestion: {
    textField: {
      '& .MuiInputBase-input': {
        width: '600px',
        height: '44px',
        border: `1.68px solid ${palette.gray.lightVariant8}`,
        padding: '0 14px',
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: 'Mulish'
      },
      '& .MuiOutlinedInput-root': {
        border: `1.68px solid ${palette.gray.lightVariant8}`,
        borderRadius: '6px',
        '&:hover fieldset': {
          border: `1.68px solid ${palette.gray.lightVariant8}`
        },
        '&.Mui-focused fieldset': {
          border: `1.68px solid ${palette.gray.lightVariant8}`
        },
        '& fieldset': {
          border: `1.68px solid ${palette.gray.lightVariant8}`
        }
      }
    }
  },
  selectedButton: {
    background: palette.primary.textVariant,
    color: palette.gray[50],
    boxShadow: 'none'
  },
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  backButton: {
    background: palette.gray[50],
    border: `0.84px solid ${palette.gray[550]}`,
    color: palette.gray[750],
    boxShadow: 'none',
    textTransform: 'capitalize',
    height: '40px',
    width: '86px',
    borderRadius: '6px',
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontWeight: 600,
    '&:hover': {
      background: palette.gray[50],
      color: palette.gray[750],
      boxShadow: 'none'
    }
  },
  questionStats: {
    color: palette.gray[750],
    fontSize: '12px',
    fontFamily: 'Mulish',
    fontWeight: 400
  },
  nextAndSubmitButton: {
    background: palette.primary.textVariant,
    color: palette.gray[50],
    boxShadow: 'none',
    textTransform: 'capitalize',
    height: '40px',
    width: '86px',
    borderRadius: '6px',
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontWeight: 800,
    '&:hover': {
      background: palette.primary.textVariant,
      color: palette.gray[50],
      boxShadow: 'none'
    },
    '&:disabled': {
      backgroundColor: palette.gray[550],
      color: palette.gray.background
    }
  },
  introDialog: {
    headerWrapper: { display: 'flex', justifyContent: 'end', alignItems: 'center', mb: '46px' },
    heading: {
      color: palette.black.main,
      textAlign: 'center',
      fontFamily: 'Mulish',
      fontSize: '28px',
      fontWeight: 700
    },
    title: {
      color: palette.gray[950],
      textAlign: 'center',
      fontFamily: 'Mulish',
      fontSize: '14px',
      fontWeight: '400'
    },
    infoButtonWrapper: {
      mt: '25px',
      display: 'flex',
      justifyContent: 'center',
      gap: '10px'
    },
    infoButton: {
      width: '180px',
      height: '36px',
      borderRadius: '24px',
      background: palette.white.smokeDark,
      fontFamily: 'Mulish',
      fontSize: '16px',
      fontWeight: 700,
      color: palette.primary[950],
      cursor: 'default',
      pointerEvents: 'none',
      textTransform: 'none',
      '&:hover': {
        background: palette.white.smokeDark
      }
    },
    surveyStartButtonWrapper: {
      mt: '46px',
      textAlign: 'center'
    },
    surveyStartButton: {
      height: '48px',
      width: '262px',
      borderRadius: '10px',
      background: `linear-gradient(272deg, #37B45B 9.5%, ${palette.primary['550']} 88.46%)`,
      color: palette.gray[50],
      fontSize: '20px',
      fontFamily: 'Mulish',
      textTransform: 'none',
      fontWeight: 800
    }
  },
  dialogContent: {
    padding: '26.88px 33.6px',
    display: 'flex',
    height: 'inherit',
    flexDirection: 'column'
  },
  surveyCompleteDialog: {
    headerWrapper: { display: 'flex', justifyContent: 'end', alignItems: 'center', mb: '36px' },
    successCheckIconButton: {
      background: palette.primary[550],
      width: '50px',
      height: '50px',
      mb: '12px',
      '& svg': {
        color: palette.white.main
      },
      pointerEvents: 'none',
      textTransform: 'none',
      '&:hover': {
        background: palette.primary[550]
      }
    },
    heading: {
      color: palette.gray[950],
      textAlign: 'center',
      fontFamily: 'Mulish',
      fontSize: '32px',
      fontWeight: 700
    },
    title: {
      color: palette.gray[950],
      textAlign: 'center',
      fontFamily: 'Mulish',
      fontSize: '16px',
      fontWeight: '400'
    },
    surveyCloseButton: {
      height: '48px',
      width: '192px',
      borderRadius: '6px',
      background: palette.primary['650'],
      color: palette.gray[50],
      fontSize: '20px',
      fontFamily: 'Mulish',
      fontWeight: 700,
      textTransform: 'none',
      '&:hover': {
        background: palette.primary['650'],
        color: palette.gray[50]
      }
    }
  },
  starRatingTypeQuestion: {
    rating: {
      '& .MuiRating-icon': {
        fontSize: 38
      },
      gap: 3,
      mt: 3
    }
  },
  checkBoxTypeQuestion: {
    checkbox: {
      p: 0,
      '&.Mui-checked': {
        color: palette.primary['600']
      }
    },
    formControlLabel: {
      '& .MuiCheckbox-root': {
        pr: 1
      },
      '& .MuiFormControlLabel-label': {
        color: 'gray.700',
        fontSize: 12,
        fontWeight: 700,
        fontFamily: 'Mulish',
        height: 16
      }
    }
  },
  inputTooltipTypeQuestion: {
    title: {
      color: 'black.main',
      fontFamily: 'Mulish',
      fontSize: 12,
      fontWeight: 700
    },
    tooltipInfoIcon: {
      height: 12,
      width: 12
    }
  }
};

export default styles;
