/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
import React, { useMemo, useState } from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// component
import DocumentListing from '../../../components/Dialogs/DocumentListing';

// utils
import { DocumentItem, DocumentsGroup } from '../../../components/Dialogs/DocumentListing/types';
import getDateString from '../../../utils/getDateString';
import { getGuidanceDocumentsSignedURL } from '../../../api/pages/GuidanceDocuments';

// styles
import styles from '../../EMAGuidance/styles/DocumentHistory.styles';

// Icons
import { FolderIcon } from '../../../assets/svgs/Icons';

const GridCellDocuments: React.FC<any> = ({ data }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenPopup = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };

  const getPDFUrlFromS3Path = async (pdfLink: string) => {
    const res: any = await getGuidanceDocumentsSignedURL({
      source: data.source,
      s3_path: pdfLink
      // eslint-disable-next-line no-console
    }).catch(err => console.error(err));
    return res?.data?.body?.s3_url;
  };

  const documents: DocumentsGroup[] = useMemo(() => {
    const docs: DocumentItem[] = data.documents.map(({ title, pdf_s3_url: pdfUrl }: any) => {
      return {
        title,
        title_link: pdfUrl,
        getPdfUrl: getPDFUrlFromS3Path
      };
    });

    const group: DocumentsGroup = {
      id: 'documents',
      categoryTitle: 'Documents',
      documents: docs
    };

    return [group];
  }, []);

  const chatRIAMetadata = useMemo(() => {
    return {
      field_regulated_product_field: data?.regulated_product_field ?? '',
      field_docket_number: data?.docket_number ?? '',
      center: data?.center ?? '',
      field_issue_datetime: data?.issue_datetime ?? '',
      title: data?.title ?? ''
    };
  }, [data]);

  const metadata = useMemo(() => {
    return [data?.title, data?.status, data?.topics_product].filter(
      (title: string) => !!title && title !== 'NA'
    );
  }, [data]);

  return (
    <Box sx={styles.textAlignment}>
      <Box alignItems='center' sx={{ cursor: 'pointer' }}>
        {data && (
          <Stack
            direction='row'
            spacing={0.5}
            alignItems='center'
            sx={styles.linkItem}
            onClick={e => handleOpenPopup(e)}>
            <FolderIcon sx={{ fontSize: 12, color: 'primary.600' }} />
            <Typography variant='subtitle2' sx={styles.pdfCellText}>
              {data?.documents?.length}
            </Typography>
          </Stack>
        )}
      </Box>

      {open && (
        <DocumentListing
          defaultDocuments={documents}
          defaultSelectedEntityId=''
          title={data?.issue_datetime ? getDateString(data?.issue_datetime) : data?.title}
          onClose={() => setOpen(false)}
          chatRIAMetadata={chatRIAMetadata}
          source='guidance'
          metadata={metadata}
          infoComponent={null}
          disableChatRia={false}
          secondaryDrawerTitle=''
        />
      )}
    </Box>
  );
};

const DocumentHistoryPDF = (params: any) => {
  return <GridCellDocuments data={params.row} />;
};

export default DocumentHistoryPDF;
