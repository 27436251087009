// @ts-nocheck
import React, { memo, MouseEvent, useContext, useEffect, useState, useRef } from 'react';

// mui
import {
  Box,
  Button,
  Collapse,
  Divider,
  Grow,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';

// types
import { cloneDeep } from 'lodash';
import { Module, Source, SourceDropdown, SourceDropDownProps } from '../types';

// custom icons
import {
  ArrowDownIcon,
  KeyDownIcon,
  KeyDownVariantIcon,
  CaretDownIcon
} from '../../../assets/svgs/Icons';

// styles
import styles from '../styles/SourceDropDown.styles';
import { SourceDropDownRoundedMenu } from '../../../components/ui/Menu';

// store
import Store from '../../../store';
import Actions from '../../../store/actions';
import CustomCheckbox from '../../../components/CustomComponents/Checkbox';
import { SOURCE_DROPDOWN_TYPES, DEFAULT_SOURCE_LABEL } from '../const';

// Component
import SetDefaultSources from './SetDefaultSources';

// used to wrap a component with a condition
// eslint-disable-next-line react/prop-types, react/jsx-props-no-spreading
const WrapIf = ({ condition, With, children, ...rest }) =>
  // eslint-disable-next-line react/prop-types, react/jsx-props-no-spreading
  condition ? <With {...rest}>{children}</With> : children;

// eslint-disable-next-line react/jsx-props-no-spreading, react/prop-types
const GrowWrapper = ({ children, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading, react/prop-types
  <Collapse
    timeout={{
      appear: 500,
      enter: 500,
      exit: 500
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading, react/prop-types
    {...rest}>
    <Stack direction='row'>
      {children}
      <Divider orientation='vertical' sx={{ ...styles.divider, ml: '2px' }} />
    </Stack>
  </Collapse>
);

const SourceDropDown = ({
  selectedSources,
  options,
  handleChange,
  anchorEl,
  setAnchorEl,
  type,
  miniminumOneSelected,
  showApplyButton,
  makeSearch,
  showOptions = false,
  setSearchHighlighted = () => {},
  showDefaultSourcesOption = false
}: SourceDropDownProps) => {
  const { dispatch } = useContext(Store) as any;
  const open = Boolean(anchorEl);
  const [tempSelectedSources, setTempSelectedSources] = useState<SourceDropdown[]>(selectedSources);
  const finalSelectedSources = showApplyButton ? tempSelectedSources : selectedSources;
  const isDropdownType =
    type === SOURCE_DROPDOWN_TYPES.APPLICATIONS_VIEW ||
    type === SOURCE_DROPDOWN_TYPES.CT_VIEW ||
    type === SOURCE_DROPDOWN_TYPES.DOCUMENTS_VIEW ||
    type === SOURCE_DROPDOWN_TYPES.MODULE_VIEW;

  // setting us as default source with open state
  const [sourceOpen, setSourceOpen] = useState<{ [key: string]: boolean }>({});

  const [isDefaultSourcesOpen, setIsDefaultSourcesOpen] = useState(false);

  const [boxMinWidth, setBoxMinWidth] = useState('fit-content');
  const boxRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (boxRef.current) {
        const maxHeight =
          window.innerHeight - (anchorEl?.getBoundingClientRect()?.bottom ?? 0) - 30 - 44;
        const contentHeight = boxRef.current.scrollHeight;

        // Check if the scrollHeight is greater than the maximum height
        const hasOverflow = contentHeight > maxHeight;

        const newMinWidth = hasOverflow ? '195px' : 'fit-content';

        // Update minWidth state only if it differs from the current state
        setBoxMinWidth(prev => (prev !== newMinWidth ? newMinWidth : prev));
      }
    };

    // Initial check
    handleResize();

    // Create a ResizeObserver to watch for changes to the Box element's size
    const resizeObserver = new ResizeObserver(handleResize);

    if (boxRef.current) {
      resizeObserver.observe(boxRef.current);
    }

    // Cleanup the observer when the component unmounts
    return () => {
      resizeObserver.disconnect();
    };
  }, [anchorEl, options, sourceOpen]);

  const selectedSourceLabel = () => {
    if (finalSelectedSources.length === 0) return DEFAULT_SOURCE_LABEL.select;
    if (type === SOURCE_DROPDOWN_TYPES.CT_VIEW) {
      const ctSource = finalSelectedSources[0];
      if (ctSource.module.length === 0) return DEFAULT_SOURCE_LABEL.select;
      if (ctSource.module.length === 1) return ctSource.module[0].label;
      if (ctSource.module.length === options[0].module.length) return DEFAULT_SOURCE_LABEL.all;
      return DEFAULT_SOURCE_LABEL.multiple;
    }

    if (finalSelectedSources.length === 1) return finalSelectedSources[0].label;
    if (finalSelectedSources.length === options.length) return DEFAULT_SOURCE_LABEL.all;
    return DEFAULT_SOURCE_LABEL.multiple;
  };

  const isSourceChecked = (source: Source) => {
    const sourceIndex = finalSelectedSources.findIndex(x => x.value === source.value);
    if (sourceIndex === -1) return false;
    const moduleCount = options.find(item => item.value === source.value)?.module?.length ?? 0;
    const sourceModuleCount = finalSelectedSources?.[sourceIndex]?.module?.length ?? 0;
    return sourceModuleCount === moduleCount;
  };

  const isSourceIntermediate = (source: Source) => {
    const sourceIndex = finalSelectedSources.findIndex(x => x.value === source.value);
    if (sourceIndex === -1) return false;
    const moduleCount = options.find(item => item.value === source.value)?.module?.length ?? 0;
    const sourceModuleCount = finalSelectedSources[sourceIndex]?.module?.length ?? 0;
    return sourceModuleCount > 0 && sourceModuleCount < moduleCount;
  };

  const isModuleChecked = (source: Source, module: Source) => {
    const sourceIndex = finalSelectedSources.findIndex(x => x.value === source.value);
    if (sourceIndex === -1) return false;
    return finalSelectedSources[sourceIndex].module?.some(x => x.value === module.value);
  };
  const handleSourceDropDownOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSourceDropDownClose = () => {
    if (showApplyButton) {
      setTempSelectedSources(selectedSources);
    }
    // focus on #quick-search-input
    const searchInput = document.getElementById('quick-search-input');
    if (searchInput) {
      searchInput.focus();
      setTimeout(() => {
        searchInput.focus();
      }, 100);
    }
    setAnchorEl(null);
    setSearchHighlighted(false);
  };

  const handleSourceChange = async (source: SourceDropdown, checked: boolean) => {
    // if miniminumOneSelected is true and checked is false, then we need to check if there is at least one source selected
    if (miniminumOneSelected && !checked && finalSelectedSources.length === 1) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'You need to select at least one option' }
      });
      return;
    }
    const newSelectedSources = cloneDeep(finalSelectedSources);
    const sourceIndex = newSelectedSources.findIndex(x => x.value === source.value) ?? -1;
    if (checked) {
      if (sourceIndex === -1) {
        newSelectedSources.push({
          label: source.label,
          value: source.value,
          module: [...(source?.module ?? [])]
        });
      } else {
        newSelectedSources[sourceIndex].module = [...(source?.module ?? [])];
      }
    } else {
      newSelectedSources.splice(sourceIndex, 1);
    }
    if (showApplyButton) setTempSelectedSources(newSelectedSources);
    else handleChange(newSelectedSources);
  };

  const handleModuleChange = async (source: SourceDropdown, module: Module) => {
    const newSelectedSources = cloneDeep(finalSelectedSources);
    const sourceIndex = newSelectedSources.findIndex(x => x.value === source.value) ?? -1;

    const moduleIndex =
      newSelectedSources[sourceIndex]?.module?.findIndex(x => x.value === module.value) ?? -1;

    // if module is already selected, remove it
    if (sourceIndex !== -1 && moduleIndex !== -1) {
      if (
        miniminumOneSelected &&
        newSelectedSources.length === 1 &&
        newSelectedSources[0].module?.length === 1
      ) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'You need to select at least one Module' }
        });
        return;
      }
      newSelectedSources[sourceIndex].module?.splice(moduleIndex, 1);
      if (newSelectedSources[sourceIndex].module?.length === 0) {
        newSelectedSources.splice(sourceIndex, 1);
      }
    }
    // if module is not selected, add it
    else if (sourceIndex !== -1 && moduleIndex === -1) {
      newSelectedSources[sourceIndex].module?.push(module);
    }
    // if source is not selected, add it with module
    else {
      newSelectedSources.push({
        ...source,
        module: [module]
      });
    }
    if (showApplyButton) setTempSelectedSources(newSelectedSources);
    else handleChange(newSelectedSources);
  };

  useEffect(() => {
    setSourceOpen(
      Object.fromEntries(
        options.map(sourceVal => [
          sourceVal.value,
          selectedSources.some(x => x.value === sourceVal.value)
        ])
      )
    );
  }, [options, selectedSources]);
  useEffect(() => {
    if (open) {
      setSearchHighlighted(true);
    } else {
      setSearchHighlighted(prev => prev || false);
    }
  }, [open, showOptions]);

  useEffect(() => {
    if (tempSelectedSources.length === 0) {
      setTempSelectedSources(selectedSources);
    }
  }, [selectedSources]);

  // DO NOT DELETE THIS. This is to be used while source is under maintenance
  // useEffect(() => {
  //   if (selectedSources) {
  //     const check = selectedSources.some(
  //       source => source.value === 'ct' && source.module.some(mod => mod.value === 'usnlm')
  //     );
  //     if (check) {
  // dispatch({
  //   type: Actions.SET_SOURCE_MAINTENANCE,
  //   value: {
  //     status: true,
  //     message:
  //       'Platform will be going for a scheduled maintenance on 7th October 2024 from 7pm EST to 8pm EST. The platform will be unavailable during this time.'
  //   }
  // });
  //     } else {
  //       dispatch({
  //         type: Actions.SET_SOURCE_MAINTENANCE,
  //         value: {}
  //       });
  //     }
  //   }
  // }, []);

  const handleApply = async () => {
    // TODO: this condition will be removed once we start to support multi ct source selection
    if (isDropdownType && type === 'ctView' && finalSelectedSources[0]?.module?.length > 1) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'You can select only one module' }
      });
      return;
    }
    handleChange(tempSelectedSources);
    makeSearch?.(tempSelectedSources);
    setAnchorEl(null);
  };

  return (
    <>
      <WrapIf
        onMouseOver={() => {
          setSearchHighlighted(true);
        }}
        onMouseLeave={() => {
          setSearchHighlighted(true);
        }}
        condition={type === SOURCE_DROPDOWN_TYPES.NAV}
        With={GrowWrapper}
        in={showOptions}
        collapsedSize={0}
        orientation='horizontal'
        sx={{
          position: 'fixed',
          zIndex: 3
        }}
        id='filterTerm'>
        <Button
          id='source-picker-button'
          onClick={handleSourceDropDownOpen}
          disableRipple
          endIcon={
            type === SOURCE_DROPDOWN_TYPES.NAV ? (
              <KeyDownIcon fontSize='small' sx={styles.keydownIconNav} />
            ) : null
          }
          sx={{
            ...styles.sourceButton,
            ...(isDropdownType && {
              color: 'primary.main',
              display: 'flex',
              alignItems: 'center'
            }),
            ...(type === SOURCE_DROPDOWN_TYPES.NAV && styles.navbarDropdown)
          }}>
          <Stack direction='row' alignItems='center' justifyContent='start'>
            {isDropdownType && (
              <>
                <Tooltip title='Switch region and sources'>
                  <Typography
                    fontSize={14}
                    fontWeight={700}
                    sx={{ textTransform: 'capitalize' }}
                    color='primary.500'>
                    {selectedSourceLabel()}
                  </Typography>
                </Tooltip>
                <Tooltip title='Switch region and sources'>
                  <ArrowDownIcon
                    sx={{
                      fontSize: 13,
                      ml: 0.75,
                      transform: open ? 'rotate(180deg)' : 'rotate(0deg)'
                    }}
                  />
                </Tooltip>
              </>
            )}
            {type === SOURCE_DROPDOWN_TYPES.DEFAULT && (
              <Stack direction='row' spacing={1} alignItems='center' height='49px' ml='14px'>
                <Stack minWidth={98}>
                  <Typography noWrap variant='body1' sx={styles.defaultSourceLabel}>
                    {selectedSourceLabel()}
                  </Typography>
                </Stack>
                <CaretDownIcon
                  sx={{
                    ...styles.keydownIcon,
                    transform: open ? 'rotate(180deg)' : 'rotate(0deg)'
                  }}
                />
              </Stack>
            )}
            {type === SOURCE_DROPDOWN_TYPES.NAV && (
              <Stack minWidth={78}>
                <Typography>{selectedSourceLabel()}</Typography>
              </Stack>
            )}
          </Stack>
        </Button>
      </WrapIf>
      <SourceDropDownRoundedMenu
        onMouseOver={() => {
          setSearchHighlighted(true);
        }}
        id='source-picker-menu'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        disablePortal
        disableAutoFocus
        open={open}
        onClose={handleSourceDropDownClose}
        sx={{
          marginTop: 0.25,
          ...(type === SOURCE_DROPDOWN_TYPES.NAV && { marginTop: 0.05 }),
          ...(type === SOURCE_DROPDOWN_TYPES.DEFAULT && {
            marginTop: 0.75,
            '& .MuiPaper-root': {
              borderRadius: 2
            }
          })
        }}
        TransitionComponent={Grow}
        MenuListProps={{
          'aria-labelledby': 'source-picker-button',
          sx: {
            minWidth: 'fit-content',
            width: anchorEl?.offsetWidth
          }
        }}>
        <Box
          ref={boxRef}
          sx={{
            minWidth: 'fit-content',
            width: anchorEl?.offsetWidth,
            maxHeight:
              window.innerHeight - (anchorEl?.getBoundingClientRect()?.bottom ?? 0) - 30 - 44,
            overflowY: 'auto',
            '@-moz-document url-prefix()': {
              minWidth: boxMinWidth,
              overflowX: 'hidden'
            }
          }}>
          {options.map((option, optionIndex) => {
            return (
              <Stack
                key={option.label}
                sx={{ ...styles.menuText, width: anchorEl?.offsetWidth, minWidth: 180 }}>
                <Tooltip title={option?.tooltip ?? null} placement='right'>
                  <ListItemButton
                    dense
                    sx={{ paddingX: 1, paddingY: 0 }}
                    onClick={() => {
                      setSourceOpen({
                        ...sourceOpen,
                        [option.value]: !sourceOpen[option.value]
                      });
                    }}>
                    <CustomCheckbox
                      checked={isSourceChecked(option)}
                      indeterminate={isSourceIntermediate(option)}
                      value={option.label}
                      onClick={event => event.stopPropagation()}
                      onChange={(event, checked) => {
                        event.stopPropagation();
                        handleSourceChange(option, checked);
                      }}
                      size='small'
                      name='radio-buttons'
                      sx={styles.checkbox}
                    />
                    <ListItemText primary={option.label} primaryTypographyProps={styles.option} />
                    <KeyDownVariantIcon
                      style={{
                        fontSize: 14,
                        transform: sourceOpen[option.value] ? 'rotate(180deg)' : 'rotate(0deg)',
                        marginRight: 3,
                        alignContent: 'center',
                        verticalAlign: 'center',
                        alignSelf: 'center'
                      }}
                    />
                  </ListItemButton>
                </Tooltip>
                {sourceOpen[option.value] && <Divider variant='middle' />}
                {option?.module && option.module?.length > 0 && (
                  <Collapse in={sourceOpen[option.value]} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      {option.module.map(module => (
                        <Tooltip
                          title={module?.tooltip ?? null}
                          key={String(module.value)}
                          placement='right'>
                          <ListItemButton
                            dense
                            key={`listitem-${String(module.value)}`}
                            sx={styles.moduleListButton}
                            onClick={() => handleModuleChange(option, module)}>
                            <CustomCheckbox
                              checked={isModuleChecked(option, module)}
                              value={module.value}
                              name='radio-buttons'
                              size='small'
                              sx={styles.checkbox}
                            />
                            <ListItemText
                              primary={module.label}
                              primaryTypographyProps={styles.optionModule}
                            />
                          </ListItemButton>
                        </Tooltip>
                      ))}
                      {optionIndex !== options.length - 1 && <Divider />}
                    </List>
                  </Collapse>
                )}
              </Stack>
            );
          })}
          {showApplyButton && (
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <Button variant='contained' sx={styles.applyButton} onClick={handleApply}>
                Apply
              </Button>
            </Box>
          )}
        </Box>

        {showDefaultSourcesOption && (
          <>
            <Divider sx={styles.setDefaultSourcesOptionDivider} />
            <Stack
              key='set-default-sources'
              sx={{
                ...styles.setDefaultSourcesOptionWrapper,
                width: anchorEl?.offsetWidth
              }}>
              <Typography
                sx={styles.setDefaultSourcesOption}
                onClick={() => {
                  setIsDefaultSourcesOpen(true);
                  setAnchorEl(null);
                }}>
                Set Default Sources
              </Typography>
            </Stack>
          </>
        )}
      </SourceDropDownRoundedMenu>

      {showDefaultSourcesOption && (
        <SetDefaultSources
          isOpen={isDefaultSourcesOpen}
          handleClose={() => {
            setIsDefaultSourcesOpen(false);
          }}
        />
      )}
    </>
  );
};

SourceDropDown.defaultProps = {
  type: SOURCE_DROPDOWN_TYPES.DEFAULT,
  miniminumOneSelected: false,
  showApplyButton: false,
  showDefaultSourcesOption: false
};

export default memo(SourceDropDown);
