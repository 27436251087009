/* eslint-disable import/prefer-default-export */
import getUserDetails from './getUserDetails';
import RESULT_VIEW_TYPES, { MODULE_TABS_SOURCE_LIST } from '../components/constants';

/**
 * Prepares the API payload for a search request.
 *
 * @param {object} searchPayload - The payload containing search parameters.
 * @param {string} viewType - The type of view for the results.
 * @param {object} resultsState - The state of the search results.
 * @param {object} currentUser - The current user details.
 * @returns {object} - The prepared API payload.
 */
export const prepareAPIPayload = (
  searchPayload: any,
  viewType: string,
  resultsState: any,
  currentUser: any
) => {
  // Retrieve user details
  const userDetails = getUserDetails(currentUser);

  // Destructure the search payload
  const {
    search_term: searchTerm,
    source: sources,
    search_type: searchType,
    approval_date: approvalDate,
    use_synonyms: useSynonyms,
    approvalPathway,
    entities,
    query,
    entity_text: entityText,
    entity_category: entityCategory
  } = searchPayload;

  // Initialize the API payload
  const API_PAYLOAD: any = {};
  API_PAYLOAD.source = sources;
  API_PAYLOAD.query = searchTerm;
  API_PAYLOAD.userDetails = userDetails;

  // Set additional properties based on search type
  if (searchType === 'advanced') {
    API_PAYLOAD.approval_date = approvalDate ?? {};
    API_PAYLOAD.use_synonyms = useSynonyms ?? false;
    API_PAYLOAD.approvalPathway = approvalPathway ?? {};
    API_PAYLOAD.role = userDetails.role;
    API_PAYLOAD.searchType = 'advanced';
  } else if (Array.isArray(entities) && entities.length > 0) {
    API_PAYLOAD.query = query;
    API_PAYLOAD.search_term = entityText;
  }

  // Add entity category if it exists
  if (entityCategory) {
    API_PAYLOAD.category_name = entityCategory;
  }

  // Add filters if they exist
  if (Object.keys(resultsState?.filters ?? {})?.length > 0) {
    API_PAYLOAD.filters = resultsState.filters;
  }

  // Set view type if provided
  if (viewType) {
    API_PAYLOAD.view_type = viewType;
  }

  // Handle search operation ID if Aria is opened
  if (resultsState?.isAriaOpened) {
    let searchOperationId = null;
    if (resultsState.viewType === RESULT_VIEW_TYPES.CT) {
      searchOperationId = resultsState?.searchIds?.ctSearchId;
    } else if (resultsState.viewType === RESULT_VIEW_TYPES.APPLICATION) {
      searchOperationId = resultsState?.searchIds?.applicationSearchId;
    } else if (MODULE_TABS_SOURCE_LIST?.includes(resultsState.viewType)) {
      searchOperationId = resultsState?.searchIds?.[`${resultsState.viewType}SearchId`];
    }
    API_PAYLOAD.search_operation_id = searchOperationId;
  }

  return API_PAYLOAD;
};
